import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  userInfo: {},
  metaData: {},
  authorized: false,
  locations: [],
  stats: {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'SET_USER_INFO':
      const {metaData, workerStats, ...rest2} = rest.value
      return { ...state, userInfo: rest2, metaData, workerStats }
    case 'SET_WORKER_STATS':
      return { ...state, workerStats: rest.value }
    case 'SET_LOCATIONS':
      return { ...state, locations: rest.value }
    case 'SET_STATS':
      return { ...state, stats: rest.value }
    case 'SET_AUTHORIZED':
      return { ...state, authorized: rest.value }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
